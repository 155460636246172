.App {
  text-align: center;
}

.App-header {
  background-color: #2d2d2d;
  padding: 10px;
  color: white;
}

.logo {
  height: 80px;
}

.text {
  font-size: 20px;
  color: blue;
}

/* Error */
.error {
  text-align: center;
  text-decoration: underline;
  font-size: 32px;
  font-family: monospace;
  letter-spacing: 5px;
}
.error_text_animated {
  background: red;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  background-size: 400% 100%;
}

/* Rainbow animation */

.rainbow {
  text-align: center;
  text-decoration: underline;
  font-size: 32px;
  font-family: monospace;
  letter-spacing: 5px;
}
.rainbow_text_animated {
  background: linear-gradient(to right, #6666ff, #0099ff , #00ff00, #ff3399, #6666ff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: rainbow_animation 6s ease-in-out infinite;
  background-size: 400% 100%;
}

@keyframes rainbow_animation {
  0%,100% {
      background-position: 0 0;
  }

  50% {
      background-position: 100% 0;
  }
}

/* NFT Styles */

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .nfts {
    display: grid;
  }
  .nft {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #2d2d2d;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 75%;
    position: relative;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  #get-address {
    margin-top: 10px;
  }
  #donate {
    font-size: 5px;
    padding: 2px 0px;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
  }
  #donate-icon {
    width: 8px;
    height: 8px;
  }
}

@media only screen and (min-width: 769px) {
  /* For laptops */
  .nfts {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .nft {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #2d2d2d;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 200px;
    position: relative;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  #donate {
    font-size: 8px;
    padding: 3px;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 5px;
    margin-right: 5px;
  }
  #donate-icon {
    width: 15px;
    height: 15px;
  }
}

.image {
  flex-shrink: 0;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
}

.image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: white;
}

.description {
  font-size: 14px;
  color: #888;
}

.nft-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Input Styles */
.input-container {
  align-items: center;
  max-width: 500px;
  margin-bottom: 20px;
  margin: 25px auto;
}

.input-field {
  flex-grow: 1;
  height: 40px;
  border: 1px solid black;
  border-radius: 4px;
  padding: 8px;
  font-size: 14px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #444444;
  color: white;
}

.button {
  display: inline-block;
  height: 40px;
  padding: 0 16px;
  margin-left: 8px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.button:hover {
  background-color: #0056b3;
}

#rate-limit {
  color: white;
}

#not-found {
  color: white;
  margin: 0 auto;
}

/* Popup window */
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: #1e1e1e;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
  text-align: center;
  z-index:9999;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #aaa;
}

.close-symbol {
  font-size: 24px;
}

.close-symbol:hover {
  color: #333;
}

/* Search collection */
.search-container {
  display: flex;
  align-items: center;
  width: 300px;
  border: 0.5px solid black;
  border-radius: 4px;
  overflow: hidden;
  margin: 0 auto;
  margin-bottom: 20px;
}

.search-input {
  flex: 1;
  border: none;
  padding: 10px;
  font-size: 16px;
}

.search-icon {
  padding: 10px;
  color: white;
  cursor: pointer;
  transition: color 0.3s ease;
}

.search-icon:hover {
  color: #888;
}







